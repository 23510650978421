
import Vue from 'vue';
import AtomText from '@/components/atoms/AtomText.vue';
import OrganismPageSubHeader from '@/components/organisms/page-header/OrganismPageSubHeader.vue';

export default Vue.extend({
  name: 'Home',
  components: { AtomText, OrganismPageSubHeader },
  metaInfo: {
    title: ' - Lottery terms of participation',
  },
});
